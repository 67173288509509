<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <div class="p-d-flex p-ai-center">
    <div class="p-mr-1"><h1>Projects</h1></div>
    <div>
      <Button
        v-if="canUpdateProject"
        label="Create"
        icon="pi pi-plus"
        class="p-button-rounded p-button-text p-button-sm"
        @click="$router.push({ name: 'ProjectCreate' })"
      />
    </div>
  </div>

  <BaseTable
    v-if="canUpdateProject"
    :data="projects"
    :columns="columns"
    @update="goToUpdate"
    @delete="deleteDocument"
    @enable="enableDocument"
  />
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="confirm"
    @display="display"
    :message="'Are you sure you want to disable?'"
  />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import BaseTable from '@/components/BaseTable'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { useDeleteDocument } from '@/composables/useDeleteDocument'
import Button from 'primevue/button.js'
import { useToast } from 'primevue/usetoast.js'
import { db } from '@/services/firebase'
import { getPermissions } from '@/helpers/permissions'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { BaseTable, ConfirmDialog, Button, BaseBreadcrumb },
  setup() {
    const projects = ref([])
    const toast = useToast()
    const router = useRouter()
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      documentId
    } = useDeleteDocument()
    const columns = ref([
      { field: 'name', header: 'Name' },
      { field: 'active', header: 'Active' }
    ])
    const { canUpdateProject } = getPermissions()
    const breadcrumbs = [{ label: 'Projects' }]

    function getProjects() {
      db.ref('projects')
        .once('value')
        .then(data => {
          projects.value = []
          data.forEach(function (snapshot) {
            const row = snapshot.val()
            row.id = snapshot.key
            projects.value.push(row)
          })
        })
    }

    function updateDocument(params, msg) {
      db.ref('projects/' + documentId.value)
        .update(params)
        .then(() => {
          getProjects()
          displayConfirmDelete.value = false
          disableConfirmButton.value = false
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: msg,
            life: 3000
          })
        })
        .catch(error => {
          toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: error.message,
            life: 3000
          })
        })
    }

    function confirm() {
      disableConfirmButton.value = true
      updateDocument({ active: false }, 'Project successfully disabled')
    }

    function display(value) {
      displayConfirmDelete.value = value
    }

    function goToUpdate(id) {
      router.push({
        name: 'ProjectUpdate',
        params: { id }
      })
    }

    function enableDocument(id) {
      documentId.value = id
      updateDocument({ active: true }, 'Project successfully enabled')
    }

    getProjects()

    return {
      projects,
      columns,
      displayConfirmDelete,
      disableConfirmButton,
      deleteDocument,
      confirm,
      display,
      goToUpdate,
      enableDocument,
      canUpdateProject,
      breadcrumbs
    }
  }
}
</script>
